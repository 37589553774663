<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
     <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
    <ServerError  />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card>
          <v-card-title class=" elevation-1">
            Edit Exclusive
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="profileForm" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="link"
                      label=" Link"
                      :rules="[rules.required]"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="title"
                      label=" Title"
                      :rules="Rules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="caption"
                      label=" Caption"
                      :rules="Rules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Select Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="12">
                        <v-img
                        contain
                          v-if="user.image && !imagePreview"
                          :src="baseURL + user.image"
                          width="150px"
                          height="200px"
                          style="align-center; object-cover:fit;"
                        />
                        <v-img
                          v-if="imagePreview"
                          :src="imagePreview"
                          width="150px"
                          height="150px"
                          class="align-center"
                        />
                        <v-btn @click="$refs.showImage.click()"
                          >Update Image
                          <v-item-group>
                            <input
                              v-show="false"
                              label="Upload Image"
                              ref="showImage"
                              id="file"
                              type="file"
                              style="display: none"
                              @change="showImage"
                            />
                          </v-item-group> </v-btn
                        ><br />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="justify-center mt-10">
                  <v-btn
                    :disabled="!valid"
                    outlined
                    color="red"
                    @click="validate()"
                  >
                    Update
                  </v-btn>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["trendingid"],
  data() {
    return {
      menu:false,
      date: new Date().toISOString().substr(0, 10),
      caption: "",
      title: "",
      file: null,
      checkbox: true,
      showPreview: false,
      imagePreview: null,
      appLoading: false,
      showsnackbar: false,
      ServerError:false,
      link: null,
      photo: null,
      user: [],
      msg: null,
      valid: true,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 10 || "Min 10 characters",
      },
      Rules: [(value) => !!value || "Required."],
    };
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    validate() {
      if (this.$refs.profileForm.validate()) {
        this.editUser();
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/home/trending/get",
        method: "GET",
        params: {
          id: this.trendingid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.link = this.user.link;
          this.title = this.user.title;
          this.caption = this.user.caption;
          this.date = this.user.expirytime.slice(0,10);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editUser() {
      this.appLoading = true;
      var user = {};
      user["link"] = this.link;
      user["title"] = this.title;
      user["caption"] = this.caption;
      user["expirytime"] = this.date;
      user["id"] = this.trendingid;
      axios({
        method: "POST",
        url: "/home/trending/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.file) {
              this.uploadImage();
            } else {
              this.getData();
              this.$router.push("/home/trendingList");
            }
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    showImage(e) {
      // var files=e.target.files
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      this.showPreview = true;
      //reader.readAsDataURL(files[0]);
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    uploadImage() {
      let formData = new FormData();
      formData.append("id", this.trendingid);
      formData.append("photo", this.file);

      axios({
        method: "POST",
        url: "/home/trending/uploadimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.$router.push("/home/trendingList");
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>